<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
             {{ lista_campos != "" ? "Editar |" : "Criar |" }} Serviços e Custos
          </h3>
        </div>
        <div class="card-body table-responsive">
          <form action="">
            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Eventos:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_eventos"
                  placeholder="Selecione o evento..."
                  v-model="form.eventos_id"
                />
              </div>
              <div class="col-md-6">
                <label class="col-md-12">Descrição do serviços:*</label>
                <select class="form-control" v-model="form.tipo_servicos_id">
                  <option :value="item.id" v-for="(item,index) in lista_tipo_eventos" :key="index">
                  {{item.nome}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="col-md-12">Quantidade:*</label>
                <input
                  required
                    type="number"
                  class="form-control"
                  v-model="form.quant"
                  placeholder="Digite a Quantidade..."
                />
              </div>
              <div v-show="form.servicos_id == 2 || form.servicos_id == 3" class="col-md-4">
                <label class="col-md-12">Valor Diaria Pessoa:*</label>
                <input
                  required
                    type="text"
                  class="form-control"
                  v-mask="variableWithMask3"

                  v-model="valor_un"
                  placeholder="Digite a descricao..."
                />
              </div>
              
              <div class="col-md-4">
                <label class="col-md-12">Valor Unitario Cobrado do Evento:*</label>
                <input
                  required
                  v-mask="variableWithMask2"

                    type="text"
                  class="form-control"
                  v-model="valor_un_evento"
                  placeholder="Digite a descricao..."
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Total:*</label>
                <input
                  required
                  v-mask="variableWithMask"

                    type="text"
                  class="form-control"
                  v-model="total"
                  placeholder="Digite a descricao..."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        eventos_id:null,
        tipo_servicos_id: null,
        valor_un: 0,
        valor_un_evento:null,
        total: null,
        quant: null,
      },
      verif: false,
      total:null,
      valor_un:null,
      valor_un_evento:null,

      variableWithMask:"",
      variableWithMask2:"",
      variableWithMask3:"",
    };
  },
  created() {
    this.preenxerCampos();
  },
  watch:{
    total: function () {
      let pa = "";
      for (let i = 0; i < this.total.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask = "R$" + pa + "#,##";
      this.form.total = parseFloat(
        this.total.replace("R$", "").replace(",", ".")
      );
    },
    valor_un: function () {
      let pa = "";
      for (let i = 0; i < this.valor_un.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask3 = "R$" + pa + "#,##";
      this.form.valor_un = parseFloat(
        this.valor_un.replace("R$", "").replace(",", ".")
      );
    },
    valor_un_evento: function () {
      let pa = "";
      for (let i = 0; i < this.valor_un_evento.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask2 = "R$" + pa + "#,##";
      this.form.valor_un_evento = parseFloat(
        this.valor_un_evento.replace("R$", "").replace(",", ".")
      );
    },
  },
  computed: {
    lista_eventos() {
      return this.$store.state.evento.lista_eventos.map((cat) => ({
        id: cat.id,
        label: cat.nome_evento,
      }));
    },
    lista_tipo_eventos() {
      return this.$store.state.servicos.lista_tipo_eventos;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.servicos.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Pdv no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("servicos/create_servicos", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "servicos",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("servicos/update_servicos", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "servicos",
      });
    },
    async preenxerCampos() {
      await this.$store.dispatch("servicos/listar_tipo_servicos");

      await this.$store.dispatch("evento/listar_eventos");
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          eventos_id:this.lista_campos.eventos_id,
        tipo_servicos_id: this.lista_campos.tipo_servicos_id,
        quant:  this.lista_campos.quant+'',
        };
        this.valor_un= 'R$'+this.lista_campos.valor_un,
        this.total= 'R$'+this.lista_campos.total,
        this.valor_un_evento= 'R$'+this.lista_campos.valor_un_evento



    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>